//@ts-check
var faktor = 1.0;

function addSendemast(divId, label, labelLeft, labelTop) {
  var img = document.createElement("p");
  var div = document.getElementById(divId);
  var divTop = div.offsetTop;
  img.innerHTML =
    "<img src='img/mod_interactive_map/sendemast.png' class='sendemast' alt='" +
    label +
    "' title='" +
    label +
    "' width='" +
    50 * faktor +
    "px'  height='" +
    50 * faktor +
    "px'>";
  img.style.width = 50 * faktor + "px";
  img.style.height = 50 * faktor + "px";
  img.style.position = "absolute";
  img.style.zIndex = 2;
  img.margin = 0;
  img.padding = 0;
  img.style.left = (labelLeft - 15) * faktor + "px";
  img.style.top = (labelTop - 20) * faktor + "px";
  div.appendChild(img);
}

function toggleMap(divId, name) {
  $("#" + divId).css(
    "background-image",
    "url(img/mod_interactive_map/Karte" + name + ".png)"
  );
}

function addRegion(divId, region, X, Y, Breite, Hoehe) {
  var lbl = document.createElement("p");
  var div = document.getElementById(divId);
  var divTop = div.offsetTop;
  lbl.innerHTML =
    "<a onmouseover='toggleMap(\"" +
    divId +
    '","' +
    region +
    "\")'><div class='region' style='width:" +
    Breite * faktor +
    "px; height:" +
    Hoehe * faktor +
    "px'></div></a>";
  lbl.style.position = "absolute";
  lbl.style.top = Y * faktor + "px";
  lbl.style.left = X * faktor + "px";
  div.appendChild(lbl);
}

function myresize(event) {
  var parentWidth = $(map)
    .parent("div")
    .css("width");
  faktor = parentWidth.replace("px", "") / 453.0;

  $(map).css("width", 453 * faktor);
  $(map).css("height", 453 * faktor);
  $(ortsnamen).css("width", 453 * faktor);
  $(ortsnamen).css("height", 453 * faktor);

  // drop all p children of map
  $("#map p").remove();

  addSendemast("map", "Aichstrut", 125, 140);
  addSendemast("map", "Alfdorf", 260, 275);
  addSendemast("map", "Alfdorf", 250, 277);
  addSendemast("map", "Alfdorf", 240, 280);
  addSendemast("map", "Buchengehren", 220, 200);
  addSendemast("map", "Nardenheim", 245, 105);
  addSendemast("map", "Stixenhof", 220, 100);
  addSendemast("map", "Hellershof", 175, 110);
  addSendemast("map", "Schillinghof", 195, 130);
  addSendemast("map", "Hüttenbühl", 205, 150);
  addSendemast("map", "Cronhütte", 165, 100);
  addSendemast("map", "Cronhütte", 168, 92);
  addSendemast("map", "Menzles", 180, 70);
  addSendemast("map", "Kaisersbach", 80, 40);
  addSendemast("map", "Pfahlbronn", 170, 275);
  addSendemast("map", "Rienharz", 120, 230);
  addSendemast("map", "Haghof", 107, 263);
  addSendemast("map", "Mannholz", 155, 205);
  addSendemast("map", "Rehnenhof", 315, 355);
  addSendemast("map", "Rehnenhof", 330, 365);

  addRegion("map", "Alfdorf", 145, 165, 115, 155);
  addRegion("map", "Welzheim", 25, 120, 75, 130);
  addRegion("map", "Spraitbach", 280, 125, 60, 80);
  addRegion("map", "Durlangen", 300, 210, 80, 60);
  addRegion("map", "Gschwend", 180, 5, 240, 80);
  addRegion("map", "Lorch", 110, 350, 120, 80);
  addRegion("map", "Mutlangen", 300, 270, 80, 70);
  addRegion("map", "SchwaebischGmuend", 230, 350, 220, 90);
  addRegion("map", "Kaisersbach", 0, 0, 160, 90);
}

myresize();

$(window).resize(function() {
  myresize();
});
